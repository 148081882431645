<template>
	<div>
		<transition
			name="payment-method-fade"
			mode="out-in"
		>
			<KeepAlive>
				<component
					:is="currentStep"
					@handleChangeStep="handleChangeStep"
					@closePopup="$emit('closePopup')"
				/>
			</KeepAlive>
		</transition>
	</div>
</template>
<script>
export default {
	name: "PaymentMethod",
	components: {
		Step1: () => import('./steps/FirstStep.vue'),
		Step2: () => import('./steps/SecondStep.vue'),
	},
	data()
	{
		return {
			step: 1
		}
	},
	computed:
		{
			currentStep()
			{
				return `Step${this.step}`;
			}
		},
	methods:
		{
			handleChangeStep(step)
			{
				this.step = step;
			}
		}
}
</script>
<style
	lang="scss"
>
.payment-method-fade-enter-active, .payment-method-fade-leave-active
{transition: .3s;}

.payment-method-fade-enter, .payment-method-fade-leave-to
{opacity: 0;}

.payment-method__wrap
{
	display: flex;
	flex-direction: column;
	gap: 12px;
}

.payment-method__header
{
	display: flex;
	flex-direction: column;
	gap: 4px;
}

.payment-method__title
{
	font-weight: 700;
	font-size: 20px;
	line-height: 160%;
	font-family: $mainFontBoldNew;
	color: $textColor;
}

.payment-method__subtitle
{
	font-weight: 400;
	font-size: 12px;
	font-family: $mainFontNew;
	color: $black;
}
</style>